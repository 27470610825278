<template>
    <div>
        <topBarY />
        <div class="bg">
            <img src="../../../../assets/common/pic/bg.jpg" alt="">
        </div>
        <div class="switchBar">
            <div class="tab cabinet" :class="{ active: switchIndex == 1 }">云智能维护管理平台</div>
        </div>
        <div class="line"></div>
        <div class="switchContiner">
            <div class="continer cabinetContiner">
                <div class="continer">
                    <div class="introduce">
                        <span class="tit">
                            云智能维护管理平台
                        </span>
                        <br>
                        <br>
                        <span class="txt">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;宁讯网络管理平台是企业级的网络管理系统，采用单一的软件解决方案，<br>
                            为网络管理员简化了大量设备管理和监控任务。多种网络都可使用一个服务<br>
                            器实现监控和维护。丰富的设置特性帮助您有效诊断网络问题，可视化网络<br>
                            地图，自动按计划执行固件升级，更新设备状态，获取失败的 警报信息，收<br>
                            集统计数据等。基于 Web 系统环境，支持多用户账号管理。多个管理员可<br>
                            以在同一个服务器上管理不同的网络，且不用直接访问各自网络的设备。
                        </span>
                        <br>
                        <br>
                    </div>
                    <img src="../../../../assets/support/managementPlatform.png" alt="">
                </div>
            </div>
        </div>
        <baseBar />
</div>
</template>

<script>
import topBarY from '@/components/topBarY.vue'
import baseBar from '@/components/baseBar.vue'

export default {
    name: 'Product',
    components: {
        topBarY,
        baseBar
    },
    data() {
        return {
            switchIndex: 1
        }
    },
    methods: {
        switchClick(index) {
            this.switchIndex = index
        }
    },
    created() {
        this.$nextTick(() => {
            // 1.禁用右键菜单
            document.oncontextmenu = new Function("event.returnValue=false");
            // 2.禁用鼠标选中
            document.onselectstart = new Function("event.returnValue=false");
            // 3.禁止键盘F12键
            document.addEventListener("keydown", function (e) {
                if (e.key == "F12") {
                    e.preventDefault(); // 如果按下键F12,阻止事件
                }
            });
        });
    }
}
</script>

<style scoped>
.bg {
    position: relative;
    width: 1920px;
    height: 480px;
    overflow: hidden;
}

.bg img {
    position: absolute;
    top: -100px;
    width: 1920px;
}

.switchBar {
    width: 1920px;
    height: 70px;
    background-color: #fff;
    text-align: center;
}

.switchBar .tab {
    display: inline-block;
    margin-right: 30px;
    margin-top: 15px;
    cursor: pointer;
    border-radius: 5px;
    width: 180px;
    height: 40px;
    background-color: #c3a35d;
    color: #f5f5f5;
    font-family: 'Microsoft Yahei';
    font-size: 16px;
    line-height: 40px;
}

.line {
    margin: 0 auto;
    width: 1400px;
    height: 1px;
    background-color: #c3a35d;
}

.switchContiner {
    width: 1920px;
    padding: 20px;
    background-color: #f7f7f7;
}

/* 智能保管柜 */
.switchContiner .cabinetContiner {
    margin: 0 auto;
    width: 1400px;
    height: 600px;
    border-radius: 5px;
    background-color: #fff;
}

.switchContiner .cabinetContiner .continer {
    position: relative;
    margin: 0 auto;
    padding-top: 40px;
    width: 1000px;
    height: 200px;
}

.switchContiner .cabinetContiner .continer .tit {
    font-size: 18px;
    margin-bottom: 20px;
}

.switchContiner .cabinetContiner .continer .txt {
    color: #999;
    font-size: 14px;
    line-height: 30px;
}

.switchContiner .cabinetContiner img {
    position: absolute;
    top: 70px;
    right: 0;
    width: 400px;
}
</style>
